<div class="table-header row">
    <div class="col-sm-9">
        <h4 class="m-4">
            Ajouter une vente spéciale
        </h4>
    </div>

    <div class="col-sm-3 d-flex justify-content-end">
        <button [disabled]="editForm.invalid" mat-raised-button class="m-4 btn-pulpo" (click)="save()">
            <mat-icon>save</mat-icon> Valider
        </button>
    </div>
</div>
<form name="productAccommodationForm" [formGroup]="editForm"
    class="d-flex flex-column justify-content-between align-items-center">
    <div class="w-100 d-flex flex-row justify-content-center">
        <mat-form-field appearance="outline" class="m-4 w-50">
            <mat-label>Adresse mail du passager</mat-label>
            <input matInput placeholder="mail" type="text" class="ps-2" formControlName="customerMail" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="m-4 w-20">
            <mat-label>Du</mat-label>
            <input matInput [matDatepicker]="picker" class="ps-2" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="m-4 w-20">
            <mat-label>Au</mat-label>
            <input matInput [matDatepicker]="picker2" class="ps-2" formControlName="endDate" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="w-100 d-flex mb-4 flex-column justify-content-center align-items-start">

        <span class="mx-4" *ngIf="!paymentTerm">100% à la réservation</span>
        <button *ngIf="!paymentTerm" mat-raised-button class="mx-4 mt-4 btn-pulpo" (click)="openPaymentTermDialog()">
            Ajouter des conditions de paiement particulières
        </button>
        <ng-container *ngIf="paymentTerm?.paymentType === PaymentTypeEnum.RULE_BASED">
            <span class="mx-4" *ngFor="let rule of paymentTerm.ruleBasedPayment.rules">- Si {{rule.period.event |
                PaymentEventTypePipe}} entre le
                {{rule.period.startDay}} et le {{rule.period.endDay}} alors paiment le {{rule.paymentDue.dayOfMonth}} du
                mois {{rule.paymentDue.monthOffset === 0 ? 'en cours' : 'suivant'}}</span>
        </ng-container>
        <ng-container *ngIf="paymentTerm?.paymentType === PaymentTypeEnum.FIXED_DATE">
            <span class="mx-4" *ngIf="paymentTerm.fixedDatePayment.hasDownpayment">Accompte :
                {{paymentTerm.fixedDatePayment.downpayment.amount}}{{paymentTerm.fixedDatePayment.downpayment.type |
                PaymentCurrencyTypePipe}}</span>
            <span class="mx-4" *ngFor="let installment of paymentTerm.fixedDatePayment.installments">-
                {{installment.amountPercentage}}%
                :
                {{installment.daysFromEvent}} {{installment.condition | PaymentConditionPipe}} {{installment.event |
                PaymentEventTypePipe}}</span>
        </ng-container>
    </div>
</form>