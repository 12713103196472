<form name="filterForm" (ngSubmit)="onSelectionChange()" class="w-100" novalidate [formGroup]="filterForm">
    <div class="row background justify-content-center filters w-100 h-25">

        <mat-form-field appearance="fill" class="col-sm-2">
            <mat-label>N° de réservation</mat-label>
            <input formControlName="id" matInput placeholder="Chercher une référence..." type="text"
                (keyup)="onSelectionChange()" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-sm-2">
            <mat-label>PNR</mat-label>
            <input formControlName="reference" matInput placeholder="Chercher un PNR..." type="text"
                (keyup)="onSelectionChange()" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-sm-2">
            <mat-label>Ville de depart</mat-label>
            <input formControlName="originCity" matInput placeholder="Chercher une ville de depart..." type="text"
                (keyup)="onSelectionChange()" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-sm-2">
            <mat-label>Ville d'arrivée</mat-label>
            <input formControlName="destinationCity" matInput placeholder="Chercher une ville d'arrivée..." type="text"
                (keyup)="onSelectionChange()" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-sm-2">
            <mat-label>Passager</mat-label>
            <input formControlName="passenger" matInput placeholder="Chercher un nom de passager..." type="text"
                (keyup)="onSelectionChange()" />
        </mat-form-field>

        <div class="col-sm-2 d-flex flex-row-reverse align-items-center">
            <button type="button" mat-flat-button class="ml-1" color="warn" (click)="onResetFilters()"
                matTooltip="Réinitialiser les filtres">
                <mat-icon>clear_all</mat-icon>
            </button>
        </div>
    </div>
</form>