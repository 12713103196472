<mat-card class="mt-lg-5 mat-elevation-z4 fixed-size">
    <mat-card-header *ngIf="title">
        <div class="row w-100 justify-content-center background corners m-2">
            <h4><strong>{{title}}</strong></h4>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center m-2" *ngIf="!isTableValid">
            <mat-card class="text-center alert-danger w-75">
                <h5> Les données renseignées ne sont pas valides </h5>
            </mat-card>
        </div>
        <div class="row justify-content-center m-5">
            <div class="col-sm-3">
            </div>
            <div class="col-6">
                <div class="d-flex justify-content-between align-items-center cards-calendar-header">
                    <button *ngIf="data" mat-raised-button class="btn-pulpo" matTooltip="Mois précédent"
                        (click)="getLastMonth()" data-testid="table-previous-button">
                        <mat-icon style="transform: scale(2)">navigate_before</mat-icon>
                    </button>

                    <h4 class="cards-calendar-month font-weight-bold colored">{{currentDate.locale('fr').format('MMMM
                        YYYY')}}</h4>

                    <button mat-raised-button class="btn-pulpo" matTooltip="Mois suivant" (click)="getNextMonth()"
                        data-testid="table-next-button">
                        <mat-icon style="transform: scale(2)">navigate_next</mat-icon>
                    </button>
                </div>
            </div>
            <div class="col-sm-1">
            </div>
            <div class="col-sm-1">
                <button *ngIf="showButtons && showBack" mat-raised-button class="btn-pulpo" (click)="back.emit()"
                    matTooltip="Retourner à la liste des options">
                    <mat-icon>reply_all</mat-icon>
                </button>
            </div>
            <div class="col-sm-1">
                <button *ngIf="showButtons" mat-raised-button class="" [disabled]="!isTableValid"
                    [class.btn-disabled]="!isTableValid" [loading]="isSaving" [class.btn-pulpo]="isTableValid"
                    matTooltip="Sauvegarder la grille tarifaire" (click)="saveData()" data-testid="table-save-button">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
        <div class="row justify-content-center mt-lg-2">
            <div class="col-sm-2 me-6 d-flex flex-column align-items-center" *ngIf="type === 'STOCK'">
                <div class="resetResaTip d-flex justify-content-center align-items-center">
                    <div class="d-flex align-items-center">
                        <h6 class="fw-bold pt-2 ms-4 me-4">RAZ Colonne Résa en cours</h6>
                    </div>
                    <button matTooltip="Réinitialiser" mat-icon-button (click)="deleteResa.emit()">
                        <mat-icon class="red-colored">delete_sweep</mat-icon>
                    </button>
                </div>
                <div class="tooltipReset mt-2 p-2">
                    La colonne "résa en cours" sera mise à zéro.
                    Cela n'affectera pas les réservations effectuées.
                    Le stock ne sera pas modifié mais la dispo s'ajustera automatiquement.
                    Le nombre total de réservations éffectuées sur ce stock restera comptabilité
                    dans la colonne "Résa TTL".
                </div>
            </div>
            <div class="col-sm-9" *ngIf="columns && columns.length > 0">
                <ag-grid-angular rowSelection='multiple' data-testid="table" style="width: 100%" class="ag-theme-alpine"
                    [rowClassRules]="rowClassRules" (cellEditingStopped)="cellEdit($event)" domLayout="autoHeight"
                    [columnDefs]="columns" (gridReady)="onGridReady($event)" (cellValueChanged)="startCellEdit()"
                    (cellMouseOver)="showLogging($event)" (cellMouseOut)="hideLogging()"
                    [rowData]="dataMap.get(currentDate.format('MM/YYYY'))"
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'></ag-grid-angular>
                <div class="spinner-box" *ngIf="isSaving">
                    <mat-progress-spinner class="spinner" mode="indeterminate" diameter="50"></mat-progress-spinner>
                </div>
            </div>

            <ng-container *ngIf="type === 'FLIGHT' || type === 'NEW-FLIGHT'">
                <div class="col-sm-3 pulpo-border pt-2" style="height:fit-content" *ngIf="selectedFlightCell">
                    <div class="w-100 d-flex flex-row justify-content-between p-2">
                        <span class="centered-span">{{ selectedFlightCell.outboundFlightDepartureDate | date:'dd/MM/YY'
                            }}</span>
                        <span class="centered-span">{{ selectedFlightCell.outboundFlightDepartureIata }}</span>
                        <span class="centered-span">{{ selectedFlightCell.outboundFlightDepartureDate | date:'HH:mm'
                            }}</span>

                        <span class="centered-span">{{ selectedFlightCell.outboundFlightArrivalIata }}</span>
                        <span class="centered-span">{{ selectedFlightCell.outboundFlightArrivalDate | date:'HH:mm'
                            }}</span>

                        <span class="centered-span">{{ selectedFlightCell.outboundFlightArrivalDate | date:'dd/MM'
                            }}</span>
                        <div class="d-flex flex-column">
                            <span *ngFor="let flight of selectedFlightCell.outboundFlightReference?.split(' ')">
                                {{flight}}
                            </span>
                        </div>
                    </div>

                    <div class="w-100 d-flex flex-row justify-content-between p-2 mt-2 pulpo-background-extra-light"
                        *ngIf="selectedFlightCell.returnFlightDepartureDate">
                        <span class="centered-span">{{ selectedFlightCell.returnFlightDepartureDate | date:'dd/MM/YY'
                            }}</span>
                        <span class="centered-span">{{ selectedFlightCell.returnFlightDepartureIata }}</span>
                        <span class="centered-span">{{ selectedFlightCell.returnFlightDepartureDate | date:'HH:mm'
                            }}</span>

                        <span class="centered-span">{{ selectedFlightCell.returnFlightArrivalIata }}</span>
                        <span class="centered-span">{{ selectedFlightCell.returnFlightArrivalDate | date:'HH:mm'
                            }}</span>
                        <span class="centered-span">{{ selectedFlightCell.returnFlightArrivalDate | date:'dd/MM'
                            }}</span>
                        <div class="d-flex flex-column">
                            <span *ngFor="let flight of selectedFlightCell.returnFlightReference?.split(' ')">
                                {{flight}}
                            </span>
                        </div>
                    </div>

                    <div *ngIf="type === 'FLIGHT'" class="w-100 d-flex flex-row justify-content-between mt-4 pb-2">
                        <span class="centered-span pulpo-background p-2" style="color:white">Taxes incl. {{
                            selectedFlightCell.airportTaxes ? selectedFlightCell.airportTaxes : '-' }} €</span>
                        <span class="centered-span pulpo-background p-2" style="color:white">Prix: {{
                            selectedFlightCell.paxPrice }} €</span>
                    </div>
                    <div *ngIf="type === 'NEW-FLIGHT'" class="w-100 d-flex flex-row justify-content-between mt-4 pb-2">
                        <span class="centered-span pulpo-background p-2" style="color:white">Taxes incl. {{
                            selectedFlightCell.taxPrice ? selectedFlightCell.taxPrice : '-' }} €</span>
                        <span class="centered-span pulpo-background p-2" style="color:white">Prix: {{
                            selectedFlightCell.paxPrice }} €</span>
                    </div>
                    <div *ngIf="type === 'NEW-FLIGHT'" class="w-100 d-flex flex-row justify-content-start mt-4 pb-2">
                        <span class="centered-span pulpo-background p-2" style="color:white">Source: {{
                            selectedFlightCell.cacheSource }} </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>
<div *ngIf="currentBookings" id="bookingsPopUp" class="d-flex flex-column justify-content-between align-items-start p-2"
    [style.top.px]="modalTop" [style.left.px]="modalLeft">
    <h6>Réservations</h6>
    <span *ngFor="let booking of currentBookings"> - {{booking}}</span>
</div>

<div *ngIf="currentLogging" id="loggingPopUp" class="d-flex flex-column justify-content-between align-items-start p-2"
    [style.top.px]="modalTop" [style.left.px]="modalLeft">
    <h6>Logging</h6>
    <span>{{currentLogging}}</span>
</div>
