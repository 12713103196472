import { Inject, Injectable } from '@angular/core';
import { NewFlightCacheCell } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';
import { Dayjs } from 'dayjs';

@Injectable({ providedIn: 'root' })
export class FlightPriceService extends ApiService<NewFlightCacheCell> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'flight/api/price', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:4300';
    }
  }

  getMonthlyFlightCacheCell(request: {
    month: number;
    year: number;
    flightReferenceId: string;
    outboundFlightDepartureIata: string;
    outboundFlightArrivalIata: string;
    returnFlightDepartureIata: string;
    returnFlightArrivalIata: string;
  }): Observable<HttpResponse<NewFlightCacheCell[]>> {
    return this.http.get<NewFlightCacheCell[]>(
      `${this.url}/${this.endpoint}/monthly`,
      { params: this.createRequestOption(request), observe: 'response' }
    );
  }

  updateFlightCacheCells(
    flightCacheCells: any[]
  ): Observable<HttpResponse<NewFlightCacheCell[]>> {
    return this.http.post<NewFlightCacheCell[]>(
      `${this.url}/${this.endpoint}/multiple`,
      flightCacheCells,
      { observe: 'response' }
    );
  }

  updateRangeFlightCacheCells(
    id: string,
    requestUpdateFlightCacheCells: {
      startRangeDate: Dayjs;
      endRangeDate: Dayjs;
      durations: number[];
      outboundFlightDepartureIata: string;
      outboundFlightArrivalIata: string;
      returnFlightDepartureIata: string;
      returnFlightArrivalIata: string;
      paxPriceReference: number;
    }
  ): Observable<HttpResponse<NewFlightCacheCell[]>> {
    return this.http.post<NewFlightCacheCell[]>(
      `${this.url}/${this.endpoint}/updateRange/${id}`,
      requestUpdateFlightCacheCells,
      { observe: 'response' }
    );
  }
}
