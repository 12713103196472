import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BookingConsultStatusDialogComponent } from '../booking-consult-status-dialog/booking-consult-status-dialog.component';
import dayjs from 'dayjs';
import { ProductUtils } from '../../../utils/productUtils';
import { TranslatorService } from '../../../utils/translator.service';
import {
  BookingStatusEnum,
  ClassificationEnum,
  Company,
  Condition,
  EnumTypeProduct,
  IBooking,
  IOldSelection,
  IPulpoBag,
  IPulpoFlightItinerary,
  IPulpoFlightOffer,
  IRoom,
  ISelection,
  ITraveler,
  FlightTicketingImpl,
} from '@pulpo/pulpo-models';
import {
  BookingBookService,
  BookingService,
  CommissionCancellationService,
  CompanyService,
  PdfService,
  StorageService,
} from '@pulpo/pulpo-api';
import { PulpoSnackbarComponent } from '../../pulpo-snackbar/pulpo-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BookingConsultBillComponent } from '../booking-consult-bill/booking-consult-bill.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonLoadingDirective } from '../../../directives';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { XmlFormatPipe } from '../../../pipes';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'booking-consult-detail',
  templateUrl: './booking-consult-detail.component.html',
  styleUrls: ['./booking-consult-detail.component.scss'],
  standalone: true,
  imports: [
    BookingConsultStatusDialogComponent,
    CommonModule,
    BookingConsultBillComponent,
    MatIconModule,
    MatButtonToggleModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonLoadingDirective,
    MatInputModule,
    MatDialogModule,
    CodemirrorModule,
    FormsModule,
    XmlFormatPipe,
  ],
})
export class BookingConsultDetailComponent implements OnInit {
  booking!: IBooking;
  rooms?: IRoom[] = [];
  travelers!: ITraveler[];
  selection!: IOldSelection;
  newSelection!: ISelection;
  adultNumber?: number;
  childNumber?: number;
  babyNumber?: number;
  flightTaxesMap = new Map();
  dayjs = dayjs;
  ticketingInput?: FlightTicketingImpl = new FlightTicketingImpl();

  editForm = this.fb.group({
    product: [''],
    productId: [-1],
    destination: [''],
    creationDate: [''],
    lastUpdate: [''],
    distribution: [''],
    owner: [''],
    nbNights: [''],
    supplier: [''],
  });
  nbnights!: string;
  endDate: any;
  date!: dayjs.Dayjs;
  additionalStartInfo!: string;
  startDate!: string;
  passagerTitleList: string[] = [];
  adult?: number;
  child?: number;
  baby?: number;
  commissionPrice!: number;
  companies!: Company[];
  company!: Company;
  isCurrentlySupplier!: boolean;
  currentCompany!: Company;
  conditions!: Condition[];
  isConsulted = false;
  lastOpened!: dayjs.Dayjs;
  flightOffer!: IPulpoFlightOffer;
  numberOfLuggage?: number;
  luggageSummary!: string;
  luggageDetails: string[] = [];
  bags?: IPulpoBag[] = [];
  isProducer!: boolean;
  savePdf?: number;
  isLoading = false;
  producerId!: number;
  BookingStatusEnum = BookingStatusEnum;
  isTicketingProcess = false;

  options = {
    lineNumbers: false,
    theme: 'material',
    mode: 'application/xml',
    readOnly: true,
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      'CodeMirror-linenumbers',
      'CodeMirror-foldgutter',
      'CodeMirror-lint-markers',
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    background: '#f5f5f5',
  };

  constructor(
    protected activatedRoute: ActivatedRoute,
    public datePipe: DatePipe,
    private readonly fb: FormBuilder,
    public dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    protected bookingService: BookingService,
    protected bookingRawService: BookingBookService,
    protected commissionCancellationService: CommissionCancellationService,
    protected companyService: CompanyService,
    private readonly translator: TranslatorService,
    private readonly pdfService: PdfService,
    private readonly storageService: StorageService
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ booking }) => {
      this.booking = booking;
      this.producerId = this.activatedRoute.snapshot.params['producerId'];

      if (this.booking.openTimeRecord === null && this.booking.id) {
        this.bookingService
          .consult(this.booking.id, ['booking'])
          .subscribe((res) => {
            if (res.body) {
              const consultedBooking = res.body;
              this.isConsulted = consultedBooking.opened;
              this.lastOpened = consultedBooking.openTimeRecord;
              this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                data: {
                  message:
                    'Vous venez de consulter cette réservation pour la première fois',
                },
                duration: 2000,
                panelClass: ['snackbar'],
              });
            }
          });
      } else {
        this.isConsulted = this.booking.opened || false;
        this.lastOpened = this.booking.openTimeRecord || dayjs();
      }

      this.travelers = JSON.parse(this.booking.travelerJson || '');

      this.editForm.patchValue({
        product: this.booking.product?.name,
        productId: this.booking.product?.id,
        destination: this.booking.product?.destination?.name,
        creationDate: this.datePipe.transform(
          booking.creationDate,
          'dd/MM/yyyy HH:mm',
          '+0200'
        ),
        lastUpdate: this.datePipe.transform(
          booking.lastUpdateDate
            ? booking.lastUpdateDate
            : booking.creationDate,
          'dd/MM/yyyy HH:mm',
          '+0200'
        ),
        nbNights: this.booking.nbNights?.replace(/\D/g, '') + ' nuits',
      });

      if (
        this.booking.classification === ClassificationEnum.OUTDATED ||
        !this.booking.classification
      ) {
        this.selection = JSON.parse(this.booking.selectionJson || '');

        this.adultNumber = this.getAdults();
        this.childNumber = this.getChildren();
        this.babyNumber = this.getBabies();
        //complete empty room information
        this.adult = 0;
        this.child = 0;
        this.baby = 0;

        this.selection.rooms = JSON.parse(this.booking.occupationJson || '');
        if (!this.selection.rooms) {
          this.selection.rooms = JSON.parse(
            this.booking.occupationJson || ''
          ).rooms;
        }
        this.rooms = this.selection.rooms;

        this.populateInformations(this.selection);
        this.retrieveCompanyInfo(this.selection);
        this.populatePassenger(this.selection);
        this.retrieveConditions(this.selection);

        this.numberOfLuggage = 0;

        if (this.selection?.flightOffer) {
          this.flightOffer = this.selection.flightOffer;
          this.flightTaxesMap.set(
            this.selection.flightOffer.relatedId,
            this.getTaxes(this.selection)
          );
          this.getContractStartDate(this.selection);
          this.numberOfLuggage = this.selection.numberOfLuggage;
          this.bags = this.selection.bags;
          this.luggageSummary = String(this.selection.numberOfLuggage);
        }
        this.getEndDate(this.selection);
      } else {
        this.newSelection = JSON.parse(this.booking.selectionJson || '');
        this.adultNumber = this.newSelection.adultNumber;
        this.childNumber = this.newSelection.childNumber;
        this.babyNumber = this.newSelection.babyNumber;
        this.adult = this.newSelection.adultNumber;
        this.child = this.newSelection.childNumber;
        this.baby = this.newSelection.babyNumber;
        this.populateInformations(this.newSelection);
        this.populatePassenger(this.newSelection);
        this.retrieveCompanyInfo(this.newSelection);
        this.retrieveConditions(this.newSelection);
        this.rooms = this.newSelection.rooms;
        this.numberOfLuggage = 0;
        if (this.newSelection?.flightOffer) {
          this.flightOffer = this.newSelection.flightOffer;
          this.flightTaxesMap.set(
            this.newSelection.flightOffer.relatedId,
            this.getTaxes(this.newSelection)
          );
          this.getContractStartDate(this.newSelection);
          this.numberOfLuggage = this.newSelection.luggageNumber;
          this.bags = this.newSelection.bags;
          this.luggageSummary = String(this.newSelection.luggageNumber);
          this.luggageDetails = this.newSelection.luggageDetails;
        }
        this.getEndDate(this.newSelection);
      }
      console.log(this.newSelection);
    });
  }

  populateInformations(selection: IOldSelection | ISelection) {
    this.date = dayjs(selection.startDate, 'YYYY-MM-DD');
    let date = dayjs(selection.startDate, 'DD/MM/YYYY');
    if (!date.isValid()) {
      date = dayjs(selection.startDate, 'YYYY-MM-DD');
    }
    this.startDate = date.format('DD/MM/YYYY');
    // filter duplicated options
    const filteredOptions: any = [];
    if (selection?.options) {
      selection.options.forEach((option) => {
        const foundOption = filteredOptions.find(
          (opt: any) => opt.id === option.id
        );
        if (!foundOption) {
          filteredOptions.push(option);
        }
      });
    }
    selection.options = filteredOptions;

    if (this.booking.categoryName?.includes('ORC')) {
      selection.distribution = 'ORCHESTRA';
    }
    this.editForm
      .get('distribution')
      ?.setValue(selection?.distribution ? selection.distribution : 'PULPO');
  }

  retrieveConditions(selection: IOldSelection | ISelection) {
    if (!selection.conditions && selection.profileId) {
      this.commissionCancellationService
        .retrieveConditions(selection.profileId)
        .subscribe((res) => {
          if (res.body) {
            const conditions = res.body;
            selection.conditions = [];
            conditions.sort((a, b) =>
              a.rank && b.rank && a.rank < b.rank ? 1 : -1
            );
            conditions.forEach((cond) => {
              selection.conditions?.push(cond.content || '');
            });
          }
        });
    }
  }

  onConsultChange() {
    if (this.booking.id) {
      this.bookingService
        .consult(this.booking.id, ['booking'])
        .subscribe((res: any) => {
          if (res.body) {
            this.isConsulted = !this.isConsulted;
            const consultedBooking = res.body;
            if (consultedBooking.openTimeRecord) {
              this.lastOpened = consultedBooking.openTimeRecord;
            }
            this.snackBar.openFromComponent(PulpoSnackbarComponent, {
              data: {
                title: 'Modification',
                message:
                  'Vous venez de changer le statut de consultation de cette réservation',
              },
              duration: 2000,
              panelClass: ['snackbar'],
            });
          }
        });
    }
  }

  changeStatus() {
    const dialogRef = this.dialog.open(BookingConsultStatusDialogComponent, {
      disableClose: false,
      data: { booking: this.booking },
      height: 'auto',
      width: '50%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== -1 && result !== 0) {
        if (result === 1 && this.booking.id) {
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Modification',
              message: 'Votre reservation a bien été mise à jour.',
            },
            duration: 4000,
            panelClass: ['snackbar'],
          });
          this.bookingService.find(this.booking.id).subscribe((bookingRes) => {
            if (bookingRes && bookingRes.body) {
              this.booking = bookingRes.body;
            }
          });
        }
      } else {
        if (result !== 0) {
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Une erreur inconnue est survenue !',
              message:
                'Une erreur est intervenue lors de la mise à jour de votre réservation ',
              backgroundColor: '#ec2b2b',
              accentColor: 'white',
            },
            duration: 5000,
            panelClass: ['snackbar'],
          });
        }
      }
    });
  }

  getDay(date: string) {
    return dayjs(date).format('DD/MM/YYYY');
  }

  getContractStartDate(selection: ISelection | IOldSelection) {
    if (selection.flightOffer) {
      const wayTrip = selection.flightOffer.way?.segments || [];
      const wayArrival = dayjs(wayTrip[wayTrip.length - 1].arrivalTime);
      let startDate = dayjs(selection.startDate, 'DD/MM/YYYY');
      if (!startDate.isValid()) {
        startDate = dayjs(selection.startDate, 'YYYY-MM-DD');
      }
      if (startDate.date() !== wayArrival.date() && wayArrival.hour() > 6) {
        this.additionalStartInfo = '  ( Arrivée +1 )';
      } else {
        this.additionalStartInfo = '';
      }
    }
  }

  getTime(date: string) {
    return dayjs(date).format('HH:mm');
  }

  previousState() {
    window.history.back();
  }

  getAdults() {
    let adult = 0;
    if (this.selection.rooms) {
      this.selection.rooms.forEach((room) => {
        adult += room.adult;
      });
    }
    return adult;
  }

  getChildren() {
    let child = 0;
    if (this.selection.rooms) {
      this.selection.rooms.forEach((room) => {
        child += room.child;
      });
    }
    return child;
  }

  getBabies() {
    let babies = 0;
    if (this.selection.rooms) {
      this.selection.rooms.forEach((room) => {
        babies += room.baby;
      });
    }
    return babies;
  }

  getStartDate(booking: IBooking) {
    const selection = JSON.parse(booking.selectionJson || '');
    if (selection && selection.startDate) {
      if (selection.startDate.includes('/')) {
        const date = dayjs(selection.startDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        );
        return date;
      }
      return selection.startDate;
    } else {
      return 'N/A';
    }
  }

  getEndDate(selection: IOldSelection | ISelection) {
    if (this.booking && selection) {
      let date = dayjs(selection.startDate, 'DD/MM/YYYY');
      if (!date.isValid()) {
        date = dayjs(selection.startDate, 'YYYY-MM-DD');
      }
      let nbNights = Number(this.booking.nbNights?.replace(/\D/g, ''));
      if (this.additionalStartInfo) {
        nbNights += 1;
      }
      const endDate = date.add(nbNights, 'days');
      this.endDate = endDate.format('DD/MM/YYYY');
    }
  }

  getTaxes(selection: any) {
    if (selection.prices.taxPrice == null) {
      let price =
        Number(selection.flightOffer.total) -
        Number(selection.flightOffer.base);
      if (selection.flightOffer.bagRealPrice) {
        price -= Number(selection.flightOffer.bagRealPrice);
      }
      return price.toFixed(2);
    } else {
      const price = Number(selection.prices.taxPrice);
      return price.toFixed(2);
    }
    return '';
  }

  parseOptionNumber(optionNumber: any, type: string) {
    if (optionNumber) {
      if (type === 'adult') {
        return Number(optionNumber.toString().charAt(0));
      }
      if (type === 'child') {
        return Number(optionNumber.toString().charAt(1));
      }
      if (type === 'baby') {
        return Number(optionNumber.toString().charAt(2));
      }
    }
    return 0;
  }

  getOptionNumber(index: number, type: string | null) {
    const selection = this.getSelection;

    if (selection && selection.optionInfoList) {
      if (type) {
        return this.parseOptionNumber(selection.optionInfoList[index], type);
      } else {
        return selection.optionInfoList[index];
      }
    }
    return 'N/A';
  }

  translateGender(sex: any): string {
    return this.translator.translateGender(sex);
  }

  translateType(type: EnumTypeProduct | undefined) {
    if (type) {
      return this.translator.translateType(type);
    }
    return '';
  }

  translateCategory(category: string) {
    return this.translator.translateCategory(category);
  }

  getTotal() {
    let price = 0;
    if (this.selection) {
      if (this.selection.optionPrice) {
        price += this.selection.optionPrice;
      }

      if (this.selection.flightPrice) {
        price += this.selection.flightPrice;
      }
      if (this.selection.insurancePrice) {
        price += this.selection.insurancePrice;
      }
    }
    return price;
  }

  translateNights(nbnights: string) {
    return nbnights.replace('nuits', '');
  }

  getStatus(booking: IBooking) {
    if (booking.status === BookingStatusEnum.VALIDATED) {
      return 'Validée';
    }
    if (booking.status === BookingStatusEnum.CANCELLED) {
      return 'Annulée';
    }
    if (booking.status === BookingStatusEnum.PENDING) {
      return 'En traitement';
    }
    return '';
  }

  roundPrice(grandTotal: string) {
    return Math.round(Number(grandTotal));
  }

  getAddress() {
    let result = '';
    if (
      this.selection &&
      this.selection.person &&
      this.selection.person.addresses &&
      this.selection.person.addresses.addresses &&
      this.selection.person.addresses.addresses.length > 0
    ) {
      this.selection.person.addresses.addresses.forEach((address) => {
        result +=
          (address.role && address.role === 'Delivery'
            ? ' Expédition : '
            : ' Paiement : ') +
          (address.street ? address.street : '') +
          ' ' +
          (address.zipCode ? address.zipCode : '') +
          ' ' +
          (address.city ? address.city : '') +
          '\n';
      });
    }
    return result;
  }

  getPhone() {
    let result = '';
    if (
      this.selection &&
      this.selection.person &&
      this.selection.person.telephones
    ) {
      if (
        this.selection.person.telephones.telephones &&
        this.selection.person.telephones.telephones.length > 0
      ) {
        this.selection.person.telephones.telephones.forEach((phone) => {
          result +=
            (phone.type && phone.type === 'Fixed' ? ' Fixe : ' : ' Mobile : ') +
            (phone.telephone ? phone.telephone + ' ' : '') +
            '\n';
        });
      }
    }
    return result;
  }

  getName() {
    let result = '';
    if (this.selection && this.selection.person && this.selection.person.name) {
      const person = this.selection.person.name;
      result +=
        (person.title ? person.title + ' ' : '') +
        (person.firstName ? person.firstName + ' ' : '') +
        (person.lastName ? person.lastName + ' ' : '');
    }
    return result;
  }

  resendBookingConfirmation() {
    this.isLoading = true;
    if (this.booking && this.booking.id) {
      this.bookingService.resendConfirmation(this.booking.id).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.body) {
            this.snackBar.openFromComponent(PulpoSnackbarComponent, {
              data: {
                title: 'Message envoyé',
                message: 'Les mails de confirmation ont bien été renvoyés',
              },
              duration: 4000,
              panelClass: ['snackbar'],
            });
          }
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }

  enlistItinerary(flightOffer: IPulpoFlightOffer): IPulpoFlightItinerary[] {
    return [
      flightOffer.way as IPulpoFlightItinerary,
      flightOffer.back as IPulpoFlightItinerary,
    ];
  }

  getFlightPrice(withLuggage: boolean) {
    if (this.flightOffer) {
      if (this.selection) {
        let price = this.selection.referenceFlightPrice
          ? Number(this.selection.referenceFlightPrice)
          : 0;
        if (this.selection.flightPrice && this.selection.flightPrice > 0) {
          price += Number(this.selection.flightPrice);
        }

        if (
          withLuggage &&
          this.selection.luggageNumber &&
          this.selection.luggageNumber > 0
        ) {
          price += Number(this.selection.luggagePrice);
        }

        return price + ' €';
      } else {
        let price = this.newSelection.prices?.referenceFlightPrice
          ? Number(this.newSelection.prices.referenceFlightPrice)
          : 0;
        if (
          this.newSelection.prices?.flightPrice &&
          this.newSelection.prices.flightPrice > 0
        ) {
          price += Number(this.newSelection.prices.flightPrice);
        }

        if (
          withLuggage &&
          this.newSelection.luggageNumber &&
          this.newSelection.luggageNumber > 0
        ) {
          price += Number(this.newSelection.prices?.luggagePrice);
        }

        return price.toFixed(2) + ' €';
      }
    }

    return '-';
  }

  getLuggagePrice() {
    if (this.selection) {
      return this.selection.luggageNumber && this.selection.luggageNumber > 0
        ? this.selection.luggagePrice?.toFixed(2) + ' €'
        : '-';
    } else {
      if (this.newSelection.prices?.paidLuggagePrice) {
        return this.newSelection.prices.paidLuggagePrice.toFixed(2) + ' €';
      }

      if (this.newSelection.flightOffer?.bagRealPrice) {
        return this.newSelection.flightOffer.bagRealPrice.toFixed(2) + ' €';
      }

      return '-';
    }
  }

  getTotalPrice() {
    if (this.selection) {
      if (this.selection.paidFlightPrice) {
        return this.selection.paidFlightPrice + ' €';
      }
      if (this.selection.referenceFlightPrice) {
        let price = this.selection.referenceFlightPrice
          ? Number(this.selection.referenceFlightPrice)
          : 0;
        if (this.selection.flightPrice) {
          price += Number(this.selection.flightPrice);
        }
        if (this.selection.luggagePrice) {
          price += Number(this.selection.luggagePrice);
        }
        return price + ' €';
      }
      return 'N/A';
    } else {
      if (this.newSelection.prices?.paidFlightPrice) {
        return this.newSelection.prices.paidFlightPrice + ' €';
      }
      if (this.newSelection.prices?.referenceFlightPrice) {
        let price = this.newSelection.prices.referenceFlightPrice
          ? Number(this.newSelection.prices.referenceFlightPrice)
          : 0;
        if (this.newSelection.prices.flightPrice) {
          price += Number(this.newSelection.prices.flightPrice);
        }
        if (this.newSelection.prices.luggagePrice) {
          price += Number(this.newSelection.prices.luggagePrice);
        }
        return price.toFixed(2) + ' €';
      }
      return 'N/A';
    }
  }

  parseDate(birthDate: any) {
    if (!birthDate || birthDate === '') {
      return '';
    }
    if (birthDate.includes('/')) {
      return birthDate;
    }
    return dayjs(birthDate).format('DD/MM/YYYY');
  }

  parseCategory(category: string) {
    return ProductUtils.translateFromCategory(category);
  }

  parseInsurancePrice(insuranceConnectorPrice: string) {
    if (insuranceConnectorPrice) {
      return Number(insuranceConnectorPrice).toFixed(2);
    }
    return 0;
  }

  parseInsuranceDate(insuranceConnectorTimeStamp: string) {
    return dayjs(insuranceConnectorTimeStamp).format('DD/MM/YYYY');
  }

  parseStatus(insuranceConnectorStatus: string) {
    if (insuranceConnectorStatus) {
      const value = insuranceConnectorStatus.toLowerCase();
      if (value === 'sold') {
        return 'OK';
      } else {
        return 'NO';
      }
    } else {
      return 'NO';
    }
    return 'NO';
  }

  private populatePassenger(selection: IOldSelection | ISelection) {
    if (this.travelers && this.travelers.length > 0) {
      if (this.travelers[0].infoJson) {
        const passagerInfos = this.travelers[0].infoJson.split('id').slice(1);
        this.passagerTitleList = [];
        passagerInfos.forEach((passenger: any) => {
          if (passenger.includes('Mrs')) {
            this.passagerTitleList.push('Mrs');
          } else {
            this.passagerTitleList.push('Mr');
          }
        });
      }
    }

    if (this.passagerTitleList.length === 0 && selection.travelers) {
      selection.travelers.forEach((tr) => {
        this.passagerTitleList.push(tr.title === 'MALE' ? 'Mr' : 'Mrs');
      });
    }
  }

  retrieveCompanyInfo(selection: IOldSelection | ISelection) {
    this.company = this.storageService.getCompany();
    if (selection.supplierId && selection.creationCompanyId) {
      this.isCurrentlySupplier =
        Number(selection.supplierId) === Number(selection.creationCompanyId);
    }

    this.companyService.findAll().subscribe((res: any) => {
      if (res.body) {
        this.companies = res.body;
        if (
          selection.distribution &&
          selection.distribution.includes('ORC') &&
          selection.supplier
        ) {
          this.editForm.get('supplier')?.setValue(selection.supplier);
        } else {
          const foundSupplier = this.companies.find(
            (company) => company.id === selection.supplierId
          );
          if (foundSupplier) {
            this.editForm.get('supplier')?.setValue(foundSupplier.name || '');
          } else {
            this.editForm.get('supplier')?.setValue('Non renseigné');
          }
        }

        if (selection.ownerId) {
          const foundOwner = this.companies.find(
            (company) => company.id === selection.ownerId
          );
          if (foundOwner) {
            this.editForm.get('owner')?.setValue(foundOwner.name || '');
          } else {
            this.editForm.get('owner')?.setValue('Non renseigné');
          }
        }
      }
    });
  }

  getLocatorCode(): string {
    if (this.selection) {
      return <string>this.selection.flightReference;
    }
    if (this.newSelection) {
      return this.newSelection.locatorCode;
    }
    return '-';
  }

  getSupplierLocatorCode(): string {
    if (this.selection) {
      return <string>this.selection.flightReference;
    }
    if (this.newSelection) {
      return this.newSelection.supplierLocatorCode;
    }
    return '-';
  }

  getDescription() {
    if (this.selection) {
      let result = '';
      if (this.selection.person && this.selection.person.description) {
        result += this.selection.person.description + ' ';
      }
      return result;
      //return this.selection && this.selection.description ? this.selection.description : '';
    }

    if (this.newSelection) {
      let result = '';
      if (this.newSelection.person && this.newSelection.person.description) {
        result += this.newSelection.person.description + ' ';
      }
      return result;
    }
    return '';
  }

  get getSelection(): ISelection | IOldSelection {
    if (this.selection) {
      return this.selection as IOldSelection;
    } else {
      return this.newSelection as ISelection;
    }
  }

  getRoomStatus(room: IRoom) {
    const relatedStatus = this.newSelection.status?.roomStatus?.find(
      (rs) => rs.relatedId === room.contractId
    );
    if (relatedStatus) {
      return relatedStatus.status;
    }
    return '';
  }

  getRoomPrice(room: IRoom) {
    const relatedPrice = this.newSelection.prices?.roomPrices?.find(
      (rp) =>
        rp.id === room.contractId &&
        rp.adult === room.adult &&
        rp.child === room.child &&
        rp.baby === room.child
    );
    if (relatedPrice) {
      return relatedPrice.price?.toFixed(2) + ' €';
    }
    return '';
  }

  getFlightTitle(itinerary: any) {
    return (
      'Vols ' +
      itinerary?.segments[0].origin +
      '-' +
      itinerary.segments[itinerary.segments?.length - 1]?.destination
    );
  }

  getPaymentPrice(payment: any) {
    return payment.price && payment.price.value
      ? (payment.price.value / 100).toFixed(2)
      : '--';
  }

  getFlightStatus() {
    return this.newSelection.status?.flightStatus;
  }

  getInsuranceStatus(): string {
    return this.newSelection.insurance.insuranceConnectorStatus
      ? this.parseStatus(this.newSelection.insurance.insuranceConnectorStatus)
      : 'NO';
  }

  getCarRentalStatus(): string {
    return this.newSelection.carRentalName ? 'OK' : 'NO';
  }

  getOptionPrice(id: number, idx: number) {
    if (this.newSelection) {
      const relatedPrice = this.newSelection.prices?.optionPrices?.find(
        (op) => op.id === id
      );
      if (relatedPrice) {
        return relatedPrice.price?.toFixed(2) + ' €';
      }
      return '';
    } else if (this.selection.optionPriceList) {
      return this.selection.optionPriceList[idx].toFixed(2) + ' €';
    }
    return '';
  }

  getFlightPriceWithoutTax(withLuggage: boolean) {
    if (this.flightOffer) {
      if (this.selection) {
        let price = this.selection.referenceFlightPrice
          ? Number(this.selection.referenceFlightPrice)
          : 0;
        if (this.selection.flightPrice && this.selection.flightPrice > 0) {
          price += Number(this.selection.flightPrice);
        }

        if (
          withLuggage &&
          this.selection.luggageNumber &&
          this.selection.luggageNumber > 0
        ) {
          price += Number(this.selection.luggagePrice);
        }

        if (this.flightTaxesMap.get(this.flightOffer.relatedId)) {
          price -= Number(this.flightTaxesMap.get(this.flightOffer.relatedId));
        }

        return price + ' €';
      } else {
        let price = this.newSelection.prices?.referenceFlightPrice
          ? Number(this.newSelection.prices.referenceFlightPrice)
          : 0;
        if (
          this.newSelection.prices?.flightPrice &&
          this.newSelection.prices.flightPrice > 0
        ) {
          price += Number(this.newSelection.prices.flightPrice);
        }

        if (
          withLuggage &&
          this.newSelection.luggageNumber &&
          this.newSelection.luggageNumber > 0
        ) {
          price += Number(this.newSelection.prices?.luggagePrice);
        }

        if (this.flightTaxesMap.get(this.flightOffer.relatedId)) {
          price -= Number(this.flightTaxesMap.get(this.flightOffer.relatedId));
        }

        return price.toFixed(2) + ' €';
      }
    }
    return '';
  }

  getRealFlightPrice(withLuggage: boolean) {
    if (this.isCompatibleFormat()) {
      if (withLuggage) {
        return this.newSelection.prices?.taxedFlightPackage?.toFixed(2) + ' €';
      } else {
        let luggagePrice = 0;
        if (this.newSelection.prices?.paidLuggagePrice) {
          luggagePrice = this.newSelection.prices.paidLuggagePrice;
        }

        if (this.newSelection.flightOffer?.bagRealPrice) {
          luggagePrice = this.newSelection.flightOffer.bagRealPrice;
        }

        let price = this.newSelection.prices?.taxedFlightPackage || 0;
        price -= luggagePrice;
        return price.toFixed(2) + ' €';
      }
    } else {
      if (this.flightOffer) {
        if (this.selection) {
          let price = this.selection.referenceFlightPrice
            ? Number(this.selection.referenceFlightPrice)
            : 0;

          if (
            withLuggage &&
            this.selection.luggageNumber &&
            this.selection.luggageNumber > 0
          ) {
            price += Number(this.selection.luggagePrice);
          }

          if (this.flightTaxesMap.get(this.flightOffer.relatedId)) {
            price -= Number(
              this.flightTaxesMap.get(this.flightOffer.relatedId)
            );
          }

          return price + ' €';
        } else {
          let price = this.newSelection.prices?.paidFlightPrice
            ? Number(this.newSelection.prices.paidFlightPrice)
            : 0;

          if (!this.newSelection.prices?.taxPrice) {
            if (this.newSelection.prices?.paidLuggagePrice && !withLuggage) {
              price -= Number(this.newSelection.prices.paidLuggagePrice);
            }
          } else {
            if (this.newSelection.prices.paidLuggagePrice && withLuggage) {
              price += Number(this.newSelection.prices.paidLuggagePrice);
            }
          }

          return price.toFixed(2) + ' €';
        }
      }
      return '';
    }
  }

  getRealFlightPriceWithoutTax(withLuggage: boolean) {
    if (this.isCompatibleFormat()) {
      return this.newSelection.prices?.untaxedFlightPackage?.toFixed(2) + ' €';
    } else {
      if (this.flightOffer) {
        if (this.selection) {
          let price = this.selection.referenceFlightPrice
            ? Number(this.selection.referenceFlightPrice)
            : 0;
          if (this.selection.flightPrice && this.selection.flightPrice > 0) {
            price += Number(this.selection.flightPrice);
          }

          if (
            withLuggage &&
            this.selection.luggageNumber &&
            this.selection.luggageNumber > 0
          ) {
            price += Number(this.selection.luggagePrice);
          }

          if (this.flightTaxesMap.get(this.flightOffer.relatedId)) {
            price -= Number(
              this.flightTaxesMap.get(this.flightOffer.relatedId)
            );
          }

          return price + ' €';
        } else {
          let price = this.newSelection.prices?.paidFlightPrice
            ? Number(this.newSelection.prices.paidFlightPrice)
            : 0;

          if (!this.newSelection.prices?.taxPrice) {
            if (this.newSelection.prices?.paidLuggagePrice && !withLuggage) {
              price -= Number(this.newSelection.prices.paidLuggagePrice);
            }
          } else {
            if (this.newSelection.prices.paidLuggagePrice && withLuggage) {
              price += Number(this.newSelection.prices.paidLuggagePrice);
            }
          }

          if (this.flightTaxesMap.get(this.flightOffer.relatedId)) {
            price -= Number(
              this.flightTaxesMap.get(this.flightOffer.relatedId)
            );
          }

          return price.toFixed(2) + ' €';
        }
        return '';
      }
      return '';
    }
  }

  getUnmargedCachePrice() {
    if (this.newSelection.flight?.flight) {
      const marginDynamic = this.newSelection.flight?.flight
        .marginDynamicBooking
        ? this.newSelection.flight.flight.marginDynamicBooking
        : 0;
      const marginFlat = this.newSelection.flight.flight
        .marginDynamicBookingFlat
        ? this.newSelection.flight.flight.marginDynamicBookingFlat
        : 0;
      let price = Number(this.newSelection.prices?.cachePrice);

      price = price * ((100 - Number(marginDynamic)) / 100);
      price -= marginFlat;
      return price.toFixed(2) + ' €';
    }

    return '';
  }

  isCompatibleFormat() {
    return this.newSelection && this.newSelection.prices?.taxedFlightPackage;
  }

  getTotalPriceBought() {
    if (this.isCompatibleFormat()) {
      if (this.newSelection.prices.toProviderPackagePriceWithMeal) {
        return this.newSelection.prices.toProviderPackagePriceWithMeal.toFixed(
          2
        );
      }
      let price = this.newSelection.prices?.toProviderPackagePrice
        ? this.newSelection.prices?.toProviderPackagePrice
        : 0;
      if (
        this.newSelection.prices?.mealPrice &&
        this.newSelection.prices.mealPrice > 0
      ) {
        price += Number(this.newSelection.prices.mealPrice);
      }
      return price.toFixed(2);
    } else {
      const difference = 0;
      if (this.flightOffer) {
        if (this.selection) {
          return 0;
        } else {
          let price = this.newSelection.prices?.paidFlightPrice
            ? Number(this.newSelection.prices.paidFlightPrice)
            : 0;
          if (
            this.newSelection.prices?.paidLuggagePrice &&
            this.newSelection.prices.paidLuggagePrice > 0
          ) {
            price += Number(this.newSelection.prices.paidLuggagePrice);
          }

          if (
            this.newSelection.prices?.totalRoomPrice &&
            this.newSelection.prices.totalRoomPrice > 0
          ) {
            price += Number(this.newSelection.prices.totalRoomPrice);
          }

          if (
            this.newSelection.prices?.totalOptionPrice &&
            this.newSelection.prices.totalOptionPrice > 0
          ) {
            price += Number(this.newSelection.prices.totalOptionPrice);
          }

          if (
            this.newSelection.prices?.mealPrice &&
            this.newSelection.prices.mealPrice > 0
          ) {
            price += Number(this.newSelection.prices.mealPrice);
          }

          if (this.newSelection.insuranceConnectorPrice) {
            price += Number(this.newSelection.insuranceConnectorPrice) / 100;
          }

          return price.toFixed(2);
        }
      }
      return difference;
    }
  }

  getInsuranceTotalPrice() {
    return this.getSelection.insurance.insuranceConnectorPrice
      ? this.parseInsurancePrice(
          this.getSelection.insurance.insuranceConnectorPrice || ''
        )
      : 0;
  }

  addSupplement(margedPrice: string) {
    return (Number(margedPrice) + 10).toFixed(2);
  }

  generatePdf(booking: any) {
    this.savePdf = booking.id;
    this.pdfService.generateBooking(booking.id).subscribe(
      (data: any) => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'Res_' + booking.id + '.pdf';
        link.click();
        this.savePdf = undefined;
      },
      (error: any) => {
        this.savePdf = undefined;
        console.error(error);
      }
    );
  }

  processTicketing() {
    const isTicketingCompliant =
      this.booking.id != null &&
      this.company.id != null &&
      this.newSelection.flightOffer.transactionId != null;

    if (isTicketingCompliant) {
      this.ticketingInput = new FlightTicketingImpl();
      this.ticketingInput.bookingId = this.booking.id;
      this.ticketingInput.companyId = this.company.id;
      this.ticketingInput.transactionId =
        this.newSelection.flightOffer.transactionId;
      this.ticketingInput.reference = this.getLocatorCode();
      this.isTicketingProcess = true;

      this.bookingRawService.ticketing(this.ticketingInput).subscribe(
        (data: any) => {
          if (data.body) {
            this.newSelection.flightTicket = data.body.flightTicketNumber;
          }
          this.isTicketingProcess = false;
        },
        (error: any) => {
          this.isTicketingProcess = false;
          console.error(error);
        }
      );
    }
  }

  isTicketingBookingEnabled() {
    let ticketingEnabled = false;
    if (this.newSelection && this.newSelection.flightOffer) {
      if (
        this.newSelection.flightOffer.provider === 'TRAVELPORT_UNIVERSAL_API' &&
        this.newSelection.flightOffer.source === '1G'
      ) {
        ticketingEnabled = true;
      }
    }
    return ticketingEnabled;
  }

  getEmissionDate() {
    if (this.newSelection && this.newSelection.emissionDate) {
      return dayjs(this.newSelection.emissionDate).format('DD/MM/yyyy HH:mm');
    }
    if (
      this.flightOffer &&
      this.flightOffer.paymentMode &&
      this.flightOffer.paymentMode !== 'BSP'
    ) {
      return dayjs(this.booking.creationDate).format('DD/MM/yyyy HH:mm');
    }

    return '';
  }

  getContractAndMealPrice() {
    let value = 0;
    if (this.newSelection) {
      if (this.newSelection.prices.totalRoomPriceWithMeal) {
        return (
          this.newSelection.prices.totalRoomPriceWithMeal.toFixed(2) + ' €'
        );
      }
      value = Number(this.newSelection.prices.totalRoomPrice);
      if (this.newSelection && this.newSelection.prices.mealPrice) {
        value += Number(this.newSelection.prices.totalRoomPrice);
        if (this.newSelection?.prices.mealPrice) {
          value += Number(this.newSelection?.prices.mealPrice);
        }
      }
    }
    return value.toFixed(2) + ' €';
  }

  formatXML(xmlString: string): string {
    const PADDING = ' '.repeat(2); // One indent
    const reg = /(>)(<)(\/*)/g;
    let formatted = '';
    let pad = 0;

    xmlString.split(reg).forEach((node) => {
      let indent = 0;
      if (node.match(/.*<\/\w+>$/)) {
        indent = -1;
      } else if (node.match(/^<\/\w/) && pad > 0) {
        pad -= 1;
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      }

      formatted += `${'\n'}${PADDING.repeat(pad)}${node}\n`;
      pad += indent;
    });

    return formatted.trim();
  }
}
