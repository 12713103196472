<form name="filterForm" (ngSubmit)="onSelectionChange()" class="w-100" novalidate [formGroup]="filterForm">
    <div class="row background justify-content-start filters w-100 h-25">
        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Référence</mat-label>
                <input formControlName="internalReference" matInput placeholder="Chercher une référence..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>

        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Pays</mat-label>
                <mat-select formControlName="countryId" (selectionChange)="onSelectionChange()">
                    <mat-option *ngFor="let country of countries" [value]="country.id">
                        {{country.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Ville</mat-label>
                <input formControlName="cityName" matInput placeholder="Chercher par ville" type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
    </div>
    <div class="row background justify-content-center filters w-100 h-25">
        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Source</mat-label>
                <mat-select formControlName="bookingProvider" (selectionChange)="onSelectionChange()">
                    <mat-option *ngFor="let source of sources" [value]="source">{{source}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Nom</mat-label>
                <input formControlName="name" matInput placeholder="Chercher par nom" type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>

        <div class="col-sm-6 d-flex flex-row-reverse align-items-center">
            <button type="button" mat-flat-button class="ml-1" color="warn" (click)="onResetFilters()"
                matTooltip="Réinitialiser les filtres">
                <mat-icon>clear_all</mat-icon>
            </button>
        </div>
    </div>
</form>