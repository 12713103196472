<form name="editForm" class="w-100" novalidate [formGroup]="filterForm">
    <div class="row background justify-content-center filters w-100 h-25">
        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="white w-75">
                <mat-label>N° Réservation</mat-label>
                <input formControlName="id" matInput placeholder="Chercher une réservation..." type="text"
                    (keyup)="onSelectionChange()" />
                <!-- <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="updateSelectedElements('id', $event)">
                        <mat-option *ngFor="let booking of filteredBookings" [value]="booking.id">
                            {{ booking.id }}
                        </mat-option>
                    </mat-autocomplete> -->
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Destination</mat-label>
                <input formControlName="destination" matInput placeholder="Chercher par destination..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Produit</mat-label>
                <input formControlName="product" matInput placeholder="Chercher par produit..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Ville de départ</mat-label>
                <input formControlName="startCity" matInput placeholder="Chercher par ville de départ..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Distributeur</mat-label>
                <input formControlName="supplier" matInput placeholder="Chercher par distributeur..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Passager</mat-label>
                <input formControlName="travelerName" matInput placeholder="Chercher par passager..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Canal</mat-label>
                <input formControlName="canal" matInput placeholder="Chercher par canal..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <!--<div class="col-sm-2">
            <mat-form-field class="w-75">
                <mat-label>Période de voyage du...</mat-label>
                <input matInput formControlName="dateBegin" [matDatepicker]="dateBegin" name="dateBegin"
                    (dateInput)="onSelectionChange()" />
                <mat-datepicker-toggle matSuffix [for]="dateBegin"> </mat-datepicker-toggle>
                <mat-datepicker #dateBegin></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-sm-2">
            <mat-form-field class="w-75" *ngIf="filterForm?.value?.dateBegin != ''">
                <mat-label>Au...</mat-label>
                <input matInput formControlName="dateEnd" [matDatepicker]="dateEnd" name="dateEnd"
                    [min]="filterForm?.value?.dateBegin" (dateInput)="onSelectionChange()" />
                <mat-datepicker-toggle matSuffix [for]="dateEnd"> </mat-datepicker-toggle>
                <mat-datepicker #dateEnd></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-sm-2">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Nom passager</mat-label>
                <mat-select formControlName="travelerName" (selectionChange)="onSelectionChange()" multiple
                    #travelerName>
                    <mat-option [value]="travelerName" *ngFor="let travelerName of travelerNames">{{ travelerName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-2">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Producteur</mat-label>
                <mat-select formControlName="companies" (selectionChange)="onSelectionChange()" multiple #selectCompany>
                    <mat-option [value]="company.id" *ngFor="let company of companies">{{ company.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <div class="col-sm-3">
            <button mat-flat-button color="warn" class="ml-2 mt-2" (click)="onResetFilters()"
                matTooltip="Réinitialiser les filtres">
                <mat-icon>clear_all</mat-icon>
            </button>
            <button mat-flat-button class="btn-pulpo ml-2 mt-2" matTooltip=" Exporter les réservations"
                (click)="exportResa.emit()" data-testid="booking-export-button">
                <mat-icon>table_chart</mat-icon>
            </button>
        </div>
    </div>
</form>