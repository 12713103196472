<div class="wrapper w-100">
    <h3 class="w-100 validation-title">{{title}}</h3>
    <h5 class="w-100 validation-content" style="white-space: pre-line">{{content}}</h5>
    <div class="d-flex flex-row justify-content-center align-items-center w-100">
        <button mat-button class="btn-red m-2" (click)="accept()">
            <mat-icon>done</mat-icon>
            Continuer
        </button>
        <button mat-button class="btn-pulpo m-2" (click)="cancel()">
            <mat-icon>close</mat-icon>
            Abandonner
        </button>
    </div>
</div>