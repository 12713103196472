<div class="sticky-button-info">
    <button mat-icon-button class="colored btn-info-custom mb-4" matTooltip="Afficher/Cacher la légende"
        (click)="this.hideInfo = !this.hideInfo">
        <mat-icon class="scale-2">info</mat-icon>
    </button>
    <mat-card *ngIf="!this.hideInfo" class="corners mat-elevation-z2">
        <div class="row justify-content-center m-2">
            <h5> Legende </h5>
        </div>
        <div class="row justify-content-center m-2">
            <mat-divider></mat-divider>
        </div>
        <div *ngFor="let line of legend" class="d-flex flex-row justify-content-left align-items-center mt-2">
            <mat-card [class]="line.colorClass" style="min-width:25px; height:25px" class="m-2" [class.mat-card]="true">
            </mat-card>
            <div class="m-2" [innerHTML]="line.innerHTML">
            </div>
        </div>
    </mat-card>
</div>