<div class="table-header row">
    <div class="col-sm-9">
        <h4 class="m-4">
            Conditions de payment
        </h4>
    </div>
    <div class="col-sm-3 d-flex justify-content-end">
        <button [disabled]="editForm.invalid" mat-raised-button class="m-4 btn-pulpo" (click)="validate()">
            <mat-icon>save</mat-icon> Valider
        </button>
    </div>
</div>
<form name="editForm" [formGroup]="editForm"
    class="d-flex flex-column justify-content-between align-items-center p-4 overflow-scroll w-100">
    <div class="w-100 d-flex flex-row justify-content-start">
        <mat-form-field appearance="outline" class="w-20">
            <mat-label>Conditions de paiement</mat-label>
            <mat-select formControlName="paymentType" (selectionChange)="onPaymentTypeChange()">
                <mat-option *hideForEnvs="['staging', 'prod']" [value]="PaymentTypeEnum.RULE_BASED">Paiement suivant
                    règles</mat-option>
                <mat-option [value]="PaymentTypeEnum.FIXED_DATE">Paiment à dates fixes avec acomptes</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="w-100 d-flex flex-row justify-content-start"
        *ngIf="editForm.get('paymentType')?.value === PaymentTypeEnum.RULE_BASED" formGroupName="ruleBasedPayment">
        <div class="d-flex w-100 flex-column" formArrayName="rules">
            <mat-hint *ngIf="rules.errors?.rulesOverlap">
                <mat-error>Les périodes doivent se suivre sans se chevaucher</mat-error>
            </mat-hint>
            <ng-container *ngFor="let rule of rules.controls; let i=index">
                <div formGroupName="{{i}}" class="d-flex flex-row w-100">
                    <div formGroupName="period" class="d-flex flex-row align-items-center w-60">
                        <mat-form-field appearance="outline" class="w-20 m-2">
                            <mat-label>Si</mat-label>
                            <mat-select formControlName="event">
                                <mat-option class="px-2" [value]="EventTypeEnum.RESERVATION">Réservation</mat-option>
                                <mat-option class="px-2" [value]="EventTypeEnum.DEPARTURE">Départ</mat-option>
                                <mat-option class="px-2" [value]="EventTypeEnum.RETURN">Retour</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-40 m-2">
                            <mat-label>Entre le</mat-label>
                            <input matInput class="px-2" formControlName="startDay" type="number" [min]="1"
                                [max]="31" />
                            <mat-hint *ngIf="rules.hasError('rulesStart') && i === 0">
                                Le premier jour doit être supérieur à 0
                            </mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-40 m-2">
                            <mat-label>et le</mat-label>
                            <input matInput class="px-2" formControlName="endDay" type="number" [min]="1" [max]="31" />
                            <mat-hint *ngIf="rules.errors?.rulesEnd && i === rules.length-1">
                                Le dernier jour doit etre egal à 31
                            </mat-hint>
                        </mat-form-field>
                        <span class="m-2">du mois +</span>
                    </div>
                    <div formGroupName="paymentDue" class="d-flex flex-row align-items-center w-40">
                        <span class="m-2">Alors paiement</span>
                        <mat-form-field appearance="outline" class="w-20 m-2">
                            <mat-label>Le</mat-label>
                            <input matInput class="px-2" formControlName="dayOfMonth" type="number" [min]="1"
                                [max]="31" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-20 m-2">
                            <mat-label>du mois</mat-label>
                            <input matInput class="px-2" formControlName="monthOffset" type="number" [min]="0"
                                [max]="1" />
                        </mat-form-field>
                        <button mat-icon-button (click)="addRule()" *ngIf="i+1 === rules?.length" class="colored mb-4">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button (click)="removeRule(i)" *ngIf="i > 0" class="red-colored mb-4">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                </div>

            </ng-container>

        </div>
    </div>

    <div class="w-100 d-flex flex-column justify-content-start"
        *ngIf="editForm.get('paymentType')?.value === PaymentTypeEnum.FIXED_DATE" formGroupName="fixedDatePayment">
        <div class="d-flex w-100 flex-row" formGroupName="downpayment">
            <mat-form-field appearance="outline" class="w-20 m-2">
                <mat-label>Acompte</mat-label>
                <input matInput class="px-2" formControlName="amount" type="number" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-20 m-2">
                <mat-label>€ / %</mat-label>
                <mat-select formControlName="type">
                    <mat-option class="px-2" [value]="CurrencyTypeEnum.PERCENTAGE">%</mat-option>
                    <mat-option class="px-2" [value]="CurrencyTypeEnum.EURO">€</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="d-flex w-100 flex-column" formArrayName="installments">
            <ng-container *ngFor="let installment of installments.controls; let i=index">
                <div formGroupName="{{i}}" class="d-flex flex-row w-100 justify-content-start align-items-center">

                    <mat-form-field appearance="outline" class="w-20 m-2">
                        <mat-label>Montant</mat-label>
                        <input matInput class="px-2" formControlName="amountPercentage" type="number" />
                    </mat-form-field>
                    <span class="m-2">%</span>
                    <mat-form-field appearance="outline" class="w-20 m-2">
                        <mat-label>Nombre de jours</mat-label>
                        <input matInput class="px-2" formControlName="daysFromEvent" type="number" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-20 m-2">
                        <mat-label>Condition</mat-label>
                        <mat-select formControlName="condition">
                            <mat-option class="px-2" [value]="ConditionEnum.AFTER">Après</mat-option>
                            <mat-option class="px-2" [value]="ConditionEnum.BEFORE">Avant</mat-option>
                            <mat-option class="px-2" [value]="ConditionEnum.ON">Le</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-20 px-2 m-2">
                        <mat-label>Période</mat-label>
                        <mat-select formControlName="event">
                            <mat-option class="px-2" [value]="EventTypeEnum.RESERVATION">Réservation</mat-option>
                            <mat-option class="px-2" [value]="EventTypeEnum.DEPARTURE">Départ</mat-option>
                            <mat-option class="px-2" [value]="EventTypeEnum.RETURN">Retour</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *hideForEnvs="['staging', 'prod']">
                        <button mat-icon-button (click)="addInstallment()" *ngIf="i+1 === rules?.length"
                            class="colored mb-4">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button (click)="removeInstallment(i)" *ngIf="i > 0" class="red-colored mb-4">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-container>
                </div>

            </ng-container>

        </div>
    </div>
</form>