import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CalendarSummaryPriceInput,
  City,
  ContractCityCacheCalendar,
} from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class CacheService extends ApiService<any> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'cache');
    if (environment.name == 'localDev') {
      this.url = 'http://localhost:8092';
    }
  }

  getStartCitiesFromContract(
    contractId: number
  ): Observable<HttpResponse<City[]>> {
    return this.http.get<City[]>(
      `${this.url}/${this.endpoint}/price/city?contractId=${contractId}`,
      { observe: 'response' }
    );
  }

  getCalendarSummary(
    calendarSummaryPriceInput: CalendarSummaryPriceInput
  ): Observable<HttpResponse<ContractCityCacheCalendar>> {
    return this.http.get<ContractCityCacheCalendar>(
      `${this.url}/${this.endpoint}/price/summary?contractId=${calendarSummaryPriceInput.contractId}&commissionProfileId=${calendarSummaryPriceInput.commissionProfileId}&startCityId=${calendarSummaryPriceInput.startCityId}&year=${calendarSummaryPriceInput.year}`,
      { observe: 'response' }
    );
  }
}
