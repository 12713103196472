import dayjs from 'dayjs';
import { CommissionProfile } from '../distribution';
import { ProductRoom } from '../product';
import { Stock } from '../stock';

export enum EnumContractPricingType {
  PER_NIGHT = 'PER_NIGHT',
  PER_DURATION = 'PER_DURATION',
}
export class Contract {
  activated?: boolean;
  canals?: string;
  commissionProfiles?: CommissionProfile[];
  duration?: number;
  endDate?: dayjs.Dayjs;
  id?: number;
  name?: string;
  productRoom?: ProductRoom;
  reference?: string;
  startDate?: dayjs.Dayjs;
  lastUpdate?: dayjs.Dayjs;
  discountStartDate?: dayjs.Dayjs;
  discountEndDate?: dayjs.Dayjs;
  stock?: Stock;
  roomRank?: number;
  pricingType?: EnumContractPricingType;
  nights?: string;
  strictDiscount?: boolean;

  productAccomId: string | number | undefined;
  commissionProfilesPromos: CommissionProfile[];
  travelexchangeTourId: string | number | undefined;
}
