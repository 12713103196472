import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import dayjs, { Dayjs } from 'dayjs';
import { map } from 'rxjs/operators';
import { DATE_FORMAT, EnumStockStatus, IStockValue } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';

type EntityArrayResponseType = HttpResponse<IStockValue[]>;

@Injectable({ providedIn: 'root' })
export class StockValueService extends ApiService<IStockValue> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/stock-values');
  }

  protected convertDateArrayFromServer(
    res: EntityArrayResponseType
  ): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((stockValue: IStockValue) => {
        stockValue.date = dayjs(stockValue.date);
      });
    }
    return res;
  }

  public findAllByStock(id: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<IStockValue[]>(`${this.url}/${this.endpoint}/stocks?stockId=${id}`, {
        observe: 'response',
      })
      .pipe(
        map((res: EntityArrayResponseType) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  public createBulk(createBulkDTO: {
    startDate: Dayjs;
    endDate: Dayjs;
    stockId: number;
    status: EnumStockStatus;
    value: number;
    retro: number;
    statusAfter: EnumStockStatus;
    available: number;
  }) {
    return this.http.post<void>(
      `${this.url}/${this.endpoint}/bulk`,
      {
        ...createBulkDTO,
      },
      { observe: 'response' }
    );
  }

  public updateMany(
    items: IStockValue[],
    stockId: number
  ): Observable<HttpResponse<IStockValue[]>> {
    const copy = this.convertDateFromClientArray(items);
    return this.http
      .put<IStockValue[]>(
        `${this.url}/${this.endpoint}/${stockId}/many`,
        copy,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((res: EntityArrayResponseType) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  public getMonthlyStockValuesByStockId(
    stockId: number,
    month: number,
    year: number
  ): Observable<EntityArrayResponseType> {
    return this.http
      .get<IStockValue[]>(`${this.url}/${this.endpoint}/monthly`, {
        observe: 'response',
        params: {
          stockId: stockId.toString(),
          month: month.toString(),
          year: year.toString(),
        },
      })
      .pipe(
        map((res: EntityArrayResponseType) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  public resetCurrentlyBooked(stockId: number): Observable<HttpResponse<void>> {
    return this.http.post<void>(
      `${this.url}/${this.endpoint}/${stockId}/reset-currently-booked`,
      {},
      { observe: 'response' }
    );
  }

  convertDateFromClientArray(stockValues: IStockValue[]): IStockValue[] {
    const copyList: IStockValue[] = [];
    stockValues.forEach((stockValue) => {
      const copy: IStockValue = Object.assign({}, stockValue, {
        date:
          stockValue.date != null && stockValue.date.isValid()
            ? stockValue.date.format(DATE_FORMAT)
            : null,
      });
      copyList.push(copy);
    });

    return copyList;
  }
}
