import { ChangeDetectorRef, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'pulpo-image-add',
  templateUrl: './pulpo-image-add.component.html',
  styleUrls: ['./pulpo-image-add.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class PulpoImageAddComponent {
  edit = false;
  tags: string[] = [];
  urlRegex =
    /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w\-.~:/?#[\]@!$&'()*+,;=]*)?$/i;
  form: FormGroup;
  addOnBlur = true;

  constructor(
    private dialogRef: MatDialogRef<PulpoImageAddComponent>,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.form = this.fb.group({
      url: [null, [Validators.required, Validators.pattern(this.urlRegex)]],
      description: [null],
      productCover: [null],
    });

    if (data.picture) {
      this.form.patchValue(data.picture);
      this.tags = data.picture.tags;
      this.edit = true;
    }
  }

  removeTag(tag: string) {
    const index = this.tags.indexOf(tag);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
  }

  tagKeyPressed(event: any) {
    if (event.key === 'Enter') {
      const value = event.target.value;
      if (value && value.length > 0) {
        this.tags.push(value);
        this.cd.detectChanges();
        event.target.value = '';
      }
    }
  }

  save() {
    const value = this.form.value;
    value.tags = this.tags;
    this.dialogRef.close(value);
  }

  close() {
    this.dialogRef.close();
  }
}
