import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from '../api.service.abstract';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingReceptiveService extends ApiService<any> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'booking/receptive');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8086';
    }
  }

  exportBaleno(
    recordId: string
  ): Observable<HttpResponse<{ recordRef: number; error: string }>> {
    return this.http.post<{ recordRef: number; error: string }>(
      `${this.url}/${this.endpoint}/${recordId}`,
      {},
      { observe: 'response' }
    );
  }
}
